// eslint-disable-next-line no-redeclare,no-unused-vars
function buildForm(cont, param, keys) {
  var card, left;
  var view = param.view;
  var object = param.object;
  var row = {};
  var visible = [];
  var splitLine = [];
  if (param.row) row = param.row;
  else if (param.id) row = getObjectValue(data, [object, param.id], {});
  view.lines = {};
  view.afterFirstSave = [];
  view.afterBuilt = [];
  param.formCont = cont;
  if (isReadMode(row, param)) param.read = true;
  if (!keys) keys = ['-block', ':chat'];

  // card
  view.addCard = function (key) {
    var label = key;
    if (!str_starts_with(key, 'block')) key = 'block' + rand();
    else if (
      parameter[object] &&
      parameter[object].labels &&
      parameter[object].labels[key]
    )
      label = getParamSettings(param, key, 'labels');
    if (!label) label = '';

    var cardId = param.ident + '_' + key;
    var cardCont = $(
      '<div id="' + cardId + '" ident="' + key + '" class="card"></div>'
    ).appendTo(cont);
    card = $('<div class="card-body"></div>').appendTo(cardCont);

    $(
      '<div class="card-titlebar d-flex justify-content-between align-items-center mb-3 link"><h5 class="card-title">' +
        label +
        '</h5><span><i class="icon fa-regular fa-angle-up fa-2x"></i></span></div>'
    )
      .appendTo(card)
      .on(click, function () {
        $(this).parents('.card').toggleClass('card-minimal');
        if (view.setHeight) view.setHeight();
        $(this).find('i,svg').toggleClass('fa-angle-up fa-angle-down');
      });

    var cont2 = $('<div class="row"></div>').appendTo(card);
    left = $('<div class="col-xl-6" data-site="left"></div>').appendTo(cont2);
    $('<div class="col-xl-6" data-site="right"></div>').appendTo(cont2);

    // scroll menu
    if (view.nav) {
      var cont3 = $('<li class="nav-item"></li>').appendTo(view.nav);
      if (!cont3.prev().length) cont3.addClass('active');
      var anker = $(
        '<a class="nav-link" href="#' +
          cardId +
          '" data-mdb-smooth-scroll="smooth-scroll" data-mdb-container="#' +
          cont.attr('id') +
          '" ident="' +
          key +
          '_anker">' +
          label +
          '</a>'
      ).appendTo(cont3);
      new mdb.SmoothScroll(anker[0], {duration: 100});
    }

    return cardCont;
  };

  // iterate form
  $.each(keys, function (i, value) {
    var type = value.slice(0, 1);
    var key = value.slice(1).trim().replace('&#x2D;', '-');

    // card
    if (type == '-') {
      if (
        !param.prepare &&
        keys[i + 1] &&
        str_starts_with(keys[i + 1], '-') &&
        !test
      )
        return;
      view.addCard(key);
    }

    // sticky card
    else if (type == '*')
      view.addCard(key).insertAfter(view.body).addClass('card-sticky');
    // sub tab
    else if (type == '[') {
      var subtab = card.children('.subtab');
      if (!subtab[0])
        subtab = $('<div class="subtab"></div>').insertBefore(left);
      var btn = $(
        '<button type="button" class="btn btn-light">' + key + '</span>'
      ).appendTo(subtab);
      var length = subtab.children('.button').length;
      if (length == 1) btn.addClass('active');
      else if (length > 1) {
        left = $('<div class="left hidden"></div>').appendTo(card);
        $('<div class="right hidden"></div>').appendTo(card);
      }
      btn.on(click, function () {
        var index = $(this).prevAll('.button').length;
        subtab
          .nextAll('.left')
          .eq(index)
          .removeClass('d-none')
          .siblings('.left')
          .addClass('d-none');
        subtab
          .nextAll('.right')
          .eq(index)
          .removeClass('d-none')
          .siblings('.right')
          .addClass('d-none');
        $(this).addClass('active').siblings().removeClass('active');
        subtab.nextAll('div:not(.hidden)').find('textarea').trigger('show');
      });
    }

    // field
    else if (type == ':') {
      var cont3 = card.find('[data-site=left]:last');
      if (key.slice(-1) == '>') {
        key = key.slice(0, key.length - 1);
        if (!cont3.hasClass('col-12'))
          cont3 = card.find('[data-site=right]:last');
      }

      // settings
      var settings = getFieldSettings(param, key);
      if (isEmptyObject(settings)) return;

      // create
      var li = buildFormLine(cont3, param, key);
      if (view.lines[key]) view.lines[key] = arrayMerge(view.lines[key], li);
      else view.lines[key] = li;
      visible.push(key);

      // fix steps field
      if (settings.field == 'steps' && $(window).height() > 750) {
        card.parent().insertBefore(view.body);
        li.find('.cont')
          .appendTo(card)
          .addClass('steps')
          .prevAll('.form-group')
          .remove();
        if (view.nav) view.nav.find('.nav-item:first').remove();
      }

      // split line
      if (
        splitLine.length &&
        (!settings.splitLine || settings.splitLineStart)
      ) {
        var wrapCont = $(
          '<div class="split-line d-flex flex-wrap"></div>'
        ).insertBefore(splitLine[0]);
        $(splitLine).each(function () {
          $(this).addClass('flex-fill').appendTo(wrapCont);
        });
        splitLine = [];
      }
      if (settings.splitLine) splitLine.push(li);
    }

    // sidebar field
    else if (view.sidebar && type == '<') {
      li = buildFormLine(
        view.sidebar,
        Object.assign({}, param, {sidebar: true}),
        key
      );
      if (view.lines[key]) view.lines[key] = arrayMerge(view.lines[key], li);
      else view.lines[key] = li;
      visible.push(key);
    }
  });
  view.formBuilt = true;

  // jump in first field if new
  if (row.id == 'new')
    setTimeout(function () {
      if (view.focusDone) return;
      var focus = getParamSettings(param, 'focus');
      if (focus)
        cont
          .find('[key=' + focus + ']')
          .find('input,button,select,textarea')
          .first()[0]
          .focus();
      else {
        var first = cont.find('input,button,select,textarea').first()[0];
        if (first) first.focus();
      }
    }, 1000);

  // mandatory check
  if (!param.bulk)
    view.beforeClose = function () {
      if (!view.mandatory || row.disabled) return;
      var empty = [];
      var mandatoryNoEscape = false;
      if (view.mandatory.length && param.id != 'new')
        $.each(view.mandatory, function (i, key) {
          if (view.hidden && inArray(key, view.hidden)) return;
          else if (view.disable && view.disable[key]) return;
          var element = view.body
            .find('.form-group[key=' + key + '] .form-control')
            .addClass('is-invalid');
          $('<div class="invalid-feedback">Pflichtfeld</div>').insertAfter(
            element
          );
          if (object) {
            var settings = getFieldSettings(param, key);
            empty.push(settings.label);
            if (settings.mandatoryNoEscape) mandatoryNoEscape = true;
          }
        });
      if (empty.length && (!view.infoShown || mandatoryNoEscape)) {
        showInfo(
          info.warning.mandatoryFields.replace('__name__', empty.join(', ')),
          'warning'
        );
        view.infoShown = true;
        return false;
      }
    };

  view.showCard = function (key) {
    view.find('.card[ident=' + key + ']').removeClass('d-none');
    if (!view.modal)
      setTimeout(function () {
        view.setHeight();
      }, 1000);
  };
  view.hideCard = function (key) {
    view.find('.card[ident=' + key + ']').addClass('d-none');
  };

  // hide elements
  if (!param.table && !param.bulk)
    $.each(view.lines, function (key, line) {
      if (isArray(line))
        $.each(line, function (i, line2) {
          hideElements(
            Object.assign({}, param, {row: row, key: key, line: line2})
          );
        });
      else
        hideElements(
          Object.assign({}, param, {row: row, key: key, line: line})
        );
    });

  // prompt save
  if (
    (param.savePrompt ||
      (row.id == 'new' && getParamSettings(param, 'savePrompt'))) &&
    !param.updating
  )
    Object.values(view.lines)[0].els[0].save(false, {});

  // instant click
  var instantClick;
  if (row.id == 'new') instantClick = getParamSettings(param, 'instantClick');
  if (param.instantClick) instantClick = param.instantClick;
  if (instantClick)
    setTimeout(function () {
      view.body
        .find('[key=' + instantClick + ']')
        .find('.link:visible,.btn:visible')
        .first()
        .trigger('click');
    }, 200);

  $.each(view.afterBuilt, function (i, func) {
    func();
  });

  // view.getLine = function (key) {};
}
